// COR DE FUNDO DO BOTÃO DO MENU LATERAL //

[dir=ltr] .main-menu.menu-light .navigation>li.active>a {
  background: linear-gradient(118deg, #46a584, rgb(116 188 163 / 37%));
}

[dir] .main-menu.menu-light .navigation>li.active>a {
  box-shadow: 0 0 10px 1px #46a584c2;
  border-radius: 4px;
}

.buttonselect {
  background-color: #2e00d6 !important;
}

// PAGINA DE LOGIN
.imagemfundo {
  background-image: url("../../assets/images/pages/fundo-poli.jpg");
  background-size: cover;
}

.opcaoAdicional {
  .botaoAceito {
    width: 100%;
    margin-top: 20px;
  }
}

.estagio {
  display: flex;
}

.estagio-msg {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px 10px 0 0;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: #dcdcdc;
}


.inputTelefone>input {
  padding: 0.571rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.Toastify>div>div>div {
  color: #fff !important;
}


.bada {
  margin-right: 5px;
  font-weight: 400;
}


.imagemAdc {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.imagemei {
  width: 100%;
  height: 100%;
}

.info-sub {
  font-size: 12px;
  margin-top: 10px;
  color: #969696;
}

.exemplo {
  display: flex;
}

.espacamento {
  margin-right: 15px;
}

.excluir {
  text-align: center;
  width: 25px;
  height: 25px;
  background-color: #ff3b3b;
  border-radius: 100%;
  margin-left: -8px;
  margin-top: -8px;
}

.excluir:hover {
  background-color: #3b3b3b;
}

.posicaoexcluir {
  position: absolute;

}























//Toast de confirmação
body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.box-conta {
  border-radius: 4px;
  padding: 20px;
  border: solid 1px #dcdcdc;
}

.react-confirm-alert-body>h1 {
  margin-top: 0;
  font-size: 20px;
}

.react-confirm-alert-body>h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group>button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}